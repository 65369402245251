.modal{
  position: absolute;
  padding: 0 30px 0 30px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
  height: fit-content;
  max-height: 95%;
  background: #FFFFFF;
  box-shadow: 0 20px 24px -4px rgba(16, 24, 40, 0.1), 0 8px 8px -4px rgba(16, 24, 40, 0.04);
  border-radius: 12px;
  overflow-y: auto;
}

.modal::-webkit-scrollbar {
  width: 0;
}
.title{

  padding: 20px 0 20px 0;
}

.create_source_item{
  margin: 0 0 10px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  flex-wrap: nowrap;
}
.create_source_item_switch{
  margin: 0 0 10px 0;
  width: 100%;
  display: flex;
  align-self: center;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
}
.switch{
  margin: 10px 0 0 0;
  display: flex;
  align-items: center;
}
.requestType_post{
  display: inline-block;
  text-transform: lowercase;
  width: fit-content;
  border-radius: 4px;
  padding: 2px 12px;
  color: #6941C6;
  background: #F9F5FF;
}
.requestType_post::first-letter{
  text-transform: uppercase;
}
.requestType_put{
  display: inline-block;
  text-transform: lowercase;
  width: fit-content;
  border-radius: 4px;
  padding: 2px 12px;
  color: #B54708;
  background: #FFFAEB;
}
.requestType_put::first-letter{
  text-transform: uppercase;
}
.requestType_patch{
  display: inline-block;
  text-transform: lowercase;
  width: fit-content;
  border-radius: 4px;
  padding: 2px 12px;
  color: #10A1C0;
  background: #F1FFFF;
}
.requestType_patch::first-letter{
  text-transform: uppercase;
}
.text legend {
  display: none;
}

.text fieldset {
  top: 0;
  height: 50px;
  border-radius: 8px;
  color: 'red';
  margin-top: 3px;
}

.container_placeholder {
  color: '#aaa'
}