.fileDropHolder{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #F9F9F9;
  border: 2px dashed #DF4036;
  border-radius: 8px;
  padding: 30px 0;
  cursor: pointer;
  margin-top: 10px;
}
.title{
  line-height: 1.5;
  letter-spacing: 0.00938em;
}
