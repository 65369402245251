.chart_container{
  border-radius: 8px;
  margin-bottom: 20px;
}
.page_title{
  font-weight: 600 !important;
  color: "#272626";
  margin: 0 10px 20px 0 !important;
}
.chart{
  border: 1px solid #D6DADE !important;
  border-radius: 8px;
  padding: 20px;
  margin: 30px 0 0 0;
}
.table{
  margin-top: 20px !important;
  margin-bottom: 30px !important;
}
.sources_statictis_title_id{
  user-select: all;
  font-size: 11px !important;
  width: 100%;
  word-break: keep-all;
  color: rgb(166, 177, 200);
}
.sources_statictis_title_name{
  width: 150px;
}
.test{
  display: flex;
  margin-top: 30px;
  margin-bottom: 30px;
}
