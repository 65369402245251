.tabs_box{
  display: flex;
  justify-content: space-between;
  margin: 20px;
}
.wrapper_btn {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin: 20px auto;
}
.wrapper_btn_back {
  display: flex;
}
.btn_back{
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 20px ;
}
.modal{
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 0 30px 0 30px;
  transform: translate(-50%, -50%);
  width: 30%;
  height: fit-content;
  background: #FFFFFF;
  border-radius: 12px;
  box-shadow: 0 20px 24px -4px rgba(16, 24, 40, 0.1), 0 8px 8px -4px rgba(16, 24, 40, 0.04);
}
.modal_title{
  padding: 20px 0 20px 0;
}
.create_source_item{
  margin: 0 0 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: nowrap;
  width: 300px
}

.StepTitle{
  text-transform: uppercase !important;
  margin: 25px 0 10px 0 !important;
}
.InactiveStepTitle{
  text-transform: uppercase !important;
  margin: 25px 0 10px 0 !important;
  color: #C01048;
}
.stepBox{
  display: flex;
  flex-direction: column;
  border: 1px solid #D6DADE;
  border-radius: 8px;
  width: 300px;
  padding: 10px;
}
.stepContainer{
  display: flex;
  justify-content: space-between
}
.editStep{
  opacity: 0;
}
.editStep:hover {
  opacity: 100%;
}
.button{
  width: 10px;
  height: 10px;
  border: 1px solid white;
  border-radius: 100%;
}
.button_container{
  display: flex;
  align-items: baseline;
  gap: 5px;
}
.level_error{
  display: inline-block;
  text-transform: lowercase;
  width: 55.33px;
  border-radius: 4px;
  padding: 2px 12px;
  color: #C01048;
  background: #FFF1F3;
  text-align: center;
}
.level_info{
  display: inline-block;
  text-transform: lowercase;
  width: 55.33px;
  border-radius: 4px;
  padding: 2px 12px;
  color: #DCAF10;
  background: #FFFAEB;
  text-align: center;
}
.level_warn{
  display: inline-block;
  text-transform: lowercase;
  width: 55.33px;
  border-radius: 4px;
  padding: 2px 12px;
  color: #B54708;
  background: #FFFAEB;
  text-align: center;
}

.container_placeholder {
  color: '#aaa'
}

.text legend {
  display: none;
}

.text fieldset {
  top: 0;
  height: 50px;
  border-radius: 8px;
  color: 'red';
  margin-top: 3px;
}

.warning_icon {
  margin-top: 25px;
  margin-left: 7px;
  color: '#C01048';
}
