.create_source_item{
  margin: 0 0 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  flex-wrap: nowrap;
}

.InputStyle{
  margin: 0 0 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: nowrap;
  width: 300px
}

.loadingWrapper{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
}
.loading{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.name{
  color: #272626;
  text-transform: capitalize;
}
.tooltipWrapper{
  padding: 16px;
  height: 100%;
  width: 100%;
}
.tooltipContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
