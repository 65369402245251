.wrapperNav {
    top: 0;
    position: sticky;
    background-color: #272626;
    height: 100vh;
    font-family: 'DM Sans', sans-serif;
}

.wrapperLogo {
    width: 100%;
    height: 140px;
    padding: 32px 32px;
}

.menu-item {
    display: flex;
    align-items: center;
    padding: 11px 0 11px 32px;
    cursor: pointer;
    color: #7E7E7E;
    margin-bottom: 24px;
}

.menu-item-text {
    width: 100%;
    padding: 0 0 0 15px;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 26px;
}

.menu-item:hover {
    color: #ffffff;
}

.menu-item:hover .wrapper-icon svg{
    color: #ffffff;
    fill: #ffffff;

}

.active:hover {
    color: #ffffff;
}

.active::after {
    content: "\A";
    display: block;
    position: relative;
    background-color: #ffffff;
    color: #DF4036;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 5px solid #DF4036;
    top: 0;
    left: 7px;
}

.active {
    background: linear-gradient(90deg, #DF4036 -16.96%, rgba(223, 64, 54, 0.7) 33.31%, rgba(4, 4, 4, 0) 125%);
    color: #ffffff;
}

.active svg {
    fill: #ffffff;
}

.wrapper-icon {
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo {
    display: inline-block;
}

.red {
    color: #DF4036;
}

.white {
    color: #ffffff;
}
.ItemContainer{
    display: flex;
    flex-direction: column;
    background-color: #272626;
    height: 100%;
}
.ItemContainer > a:nth-last-child(-n + 2){
    display: flex;
    align-items: center;
    padding: 11px 0 11px 32px;
    margin-top: auto;
    cursor: pointer;
    margin-bottom: 24px;
}

