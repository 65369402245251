.modal{
  position: absolute;
  padding: 0 30px 0 30px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
  height: fit-content;
  background: #FFFFFF;
  box-shadow: 0 20px 24px -4px rgba(16, 24, 40, 0.1), 0 8px 8px -4px rgba(16, 24, 40, 0.04);
  border-radius: 12px;
}
.title{
  padding: 20px 0 20px 0;
}

.create_source_item{
  margin: 0 0 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  flex-wrap: nowrap;
}
.switch{
  margin: 10px 0 0 0;
  display: flex;
  align-items: center;
}
.level_error{
  display: inline-block;
  text-transform: lowercase;
  width: 55.33px;
  border-radius: 4px;
  padding: 2px 12px;
  color: #C01048;
  background: #FFF1F3;
  text-align: center;
}
.level_info{
  display: inline-block;
  text-transform: lowercase;
  width: 55.33px;
  border-radius: 4px;
  padding: 2px 12px;
  color: #DCAF10;
  background: #FFFAEB;
  text-align: center;
}
.level_warn{
  display: inline-block;
  text-transform: lowercase;
  width: 55.33px;
  border-radius: 4px;
  padding: 2px 12px;
  color: #B54708;
  background: #FFFAEB;
  text-align: center;
}
.sourcesButtons{
  display: flex;
  align-items: center;
  gap: 10px;
}
.uploadedFileWrapper{
  display: flex;
  align-items: center;
  gap: 5px;
}
.fileTitle{
  font-weight: 700 !important;
}

.text legend {
  display: none;
}

.text fieldset {
  top: 0;
  height: 50px;
  border-radius: 8px;
  margin-top: 3px;
}

.container_placeholder {
  color: '#aaa'
}

.container_main_buttons {
  display: flex;
  gap: 20px;
  margin: 20px 0;
}

.container_sources_page {
  margin: 20px;
  text-align: end;
  display: flex;
  justify-content: space-between;
}