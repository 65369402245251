.pageLoginWraper {
  position: absolute;
  width: 100%;
  height: 100%;
}
.pageLoginWraper:focus {
  outline: none;
}

.loginFormWrapper{
  position: relative;
  top: 50%;
  left: 50%;
  padding: 0 30px 0 30px;
  overflow: hidden;
  transform: translate(-50%, -50%);
  width: 30%;
  height: 500px;
  background: #FFFFFF;
  box-shadow: 0 20px 24px 12px rgba(16, 24, 40, 0.1), 0 8px 8px -4px rgba(16, 24, 40, 0.04);
  border-radius: 12px;
}

.create_source_item{
  margin: 0 0 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  flex-wrap: nowrap;
}
.title{
  display: flex;
  justify-content: center;
  font-weight: 700 !important;
  font-size: 48px !important;
  padding: 30px 0 60px 0;
}
.header{
  background-color: #272626;
  margin-left: -30px;
  margin-right: -30px;
  display: flex;
  justify-content: center;
  padding: 10px 0;
}
.logo {
  display: inline-block;
}

.red {
  color: #DF4036;
}

.white {
  color: #ffffff;
}
