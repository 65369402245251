.chart_container{
  border-radius: 8px;
  margin-bottom: 20px;
}
.page_title{
  color: "#272626";
  margin: 0 10px 0 0px !important;
}
.chart{
  border: 1px solid #D6DADE !important;
  border-radius: 8px;
  padding: 20px;
  margin: 30px 0 0 0;
}

.text {
  width: 200px;
}
.text legend {
  display: none;
}

.text fieldset {
  width: 200px;
  height: 45px;
  border-radius: 8px;
  margin-top: 10px;
}

.container_option {
  display:flex;
  align-items: center;
  gap: 15px;
}
