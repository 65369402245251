
.container_database_page {
  margin: 20px;
  display: flex;
  flex-direction: column;
}

.container_header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.search_container{
  max-width: 500px;
  min-width: 465px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  
}
.search_sort {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-weight: 500;
  max-width: 500px;
  align-items: center;
  padding-bottom: 10px;
  padding-left: 30px;
}

.box_date {
  margin-left: 10px;
  display: flex;
  gap: 10px;
  margin-bottom : 10px
}

