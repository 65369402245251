.container{
  width: 100%;
  height: 100%;
  background: white
}
.text{
  color: #1D2228;
}
.copySection{
  padding: 5px;
}
.copySection:hover{
  background-color: rgba(0, 0, 0, 0.12) !important;
  border-radius: 8px;
  cursor: pointer;
}
