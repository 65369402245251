.ActiveStatus {
  text-align: center;
  display: inline-block;
  width: 85.33px;
  border-radius: 5px;
  padding: 3px;
  color: #027A48;
  background-color: #ECFDF3;
}
.InactiveStatus {
  text-align: center;
  display: inline-block;
  width: 85.33px;
  border-radius: 5px;
  padding: 3px;
  color: #C01048;
  background-color: #FFF1F3;
}
