.wrapper-doc {
  width: 100%;
  height: 100%;
  background: #F5F5F5;
  display: flex;
}

.wrapper-info {
  width: 60%;
  background: #F5F5F5;
  padding: 20px;
}

.info-title {
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}

.info-subtitle {
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 3px;
}

.info-text {
  width: 545px;
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

.info-labale {
  margin-top: 32px;
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.06em;
  color: #7E7E7E;
}

.warn-label {
  margin-top: 32px;
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.06em;
  color: #e99218;
}

.wrapper-method {
  padding: 10px 12px;
  width: fit-content;
  color: #398BEC;
  background: #F2FAFF;
  border-radius: 8px;
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  word-spacing: 16px;
}

.info-header {
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
}

.wrapper-code {
  width: 40%;
  height: 100%;
  padding: 75px 32px 0 20px;
  background: #2F343C;
}

.wrapper-code-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.code-title {
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.06em;
  color: #D6DADE;
}

.copy-btn {
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #DF4036;
  cursor: pointer;
}

.wrapper-code-text {
  font-family: 'Menlo', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  word-spacing: 5px;
}

.d {
  color: #a9a9a9;
}

.bl {
  display: block;
}

.w {
  color: #FFFFFF;
}

.g {
  color: #48A559;
}

.pl10 {
  padding-left: 20px;
}

.pl20 {
  padding-left: 40px;
}

.pl30 {
  padding-left: 60px;
}
.borderBottom{
  margin: 20px 0 20px 0;
  border-top: 0.5px solid grey;
}
