.modal{
  position: absolute;
  padding: 20px 30px 0 30px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20%;
  min-width: 400px;
  height: fit-content;
  background: #FFFFFF;
  box-shadow: 0 20px 24px -4px rgba(16, 24, 40, 0.1), 0 8px 8px -4px rgba(16, 24, 40, 0.04);
  border-radius: 12px;
}

.sub_title{
  margin: 5px 0 0 0 !important;
  color: #ACACAC;
}
