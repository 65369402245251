.modal{
  position: absolute;
  padding: 0 30px 0 30px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
  height: fit-content;
  background: #FFFFFF;
  box-shadow: 0 20px 24px -4px rgba(16, 24, 40, 0.1), 0 8px 8px -4px rgba(16, 24, 40, 0.04);
  border-radius: 12px;
}
.title{
  padding: 20px 0 20px 0;
}

.create_source_item{
  margin: 0 0 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  flex-wrap: nowrap;
}
.switch{
  margin: 10px 0 0 0;
  display: flex;
  align-items: center;
}
.type_proxy{
  display: inline-block;
  text-transform: lowercase;
  width: fit-content;
  padding: 2px 12px;
  background: #EEF4FF;
  border-radius: 4px;
  color: #3538CD;
}
.type_proxy::first-letter{
  text-transform: uppercase;
}
.type_push{
  display: inline-block;
  text-transform: lowercase;
  width: fit-content;
  padding: 2px 12px;
  background: #FFFAEB;
  border-radius: 4px;
  color: #DCAF10;
}
.type_push::first-letter{
  text-transform: uppercase;
}
.type_processing{
  display: inline-block;
  text-transform: lowercase;
  width: fit-content;
  padding: 2px 12px;
  background: #F1FFFF;
  border-radius: 4px;
  color: #10A1C0;
}
.type_processing::first-letter{
  text-transform: uppercase;
}

.text legend {
  display: none;
}

.text fieldset {
  top: 0;
  height: 50px;
  border-radius: 8px;
  color: 'red';
  margin-top: 3px;
}

.text_placeholder {
  color: 'aaa'
}
.container_button {
  display: flex;
  gap: 20px;
  margin: 20px 0;
}

.container_action_page {
  margin: 20px;
  text-align: end;
  display: flex;
  justify-content: space-between;
}
